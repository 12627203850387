// src/components/ServerInfo/CardServerName.js
import React from 'react';
import { Typography, Card, CardContent, Grid } from '@mui/material';
import {
  WbSunny as WbSunnyIcon,
  NightsStay as NightsStayIcon,
  Brightness5 as Brightness5Icon,
  Brightness3 as Brightness3Icon,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material';

function CardServerName({ data }) {
  const phaseIcons = {
    Day: <WbSunnyIcon />,
    Night: <NightsStayIcon />,
    Sunrise: <Brightness5Icon />,
    Sunset: <Brightness3Icon />,
  };

  return (
    <Card
      sx={{
        bgcolor: 'background.paper',
        color: 'text.primary',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              {data.serverName}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            {phaseIcons[data.currentPhase] || (
              <AccessTimeIcon sx={{ fontSize: 40 }} />
            )}
            <Typography variant="h6" sx={{ ml: 1 }}>
              {data.currentPhase}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CardServerName;
