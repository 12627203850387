// src/components/ServerInfo/ServerInfo.js
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CardServerName from './CardServerName';
import CardPlayerStats from './CardPlayerStats';
import CardServerTimeIP from './CardServerTimeIP';
import CardPhaseInformation from './CardPhaseInformation';

function ServerInfo({ data }) {
  // Define the initial order and mapping of the cards
  const initialCards = [
    { id: 'card0', component: (data) => <CardServerName data={data} />, gridSize: 12 },
    { id: 'card1', component: (data) => <CardPhaseInformation data={data} />, gridSize: 12 },
    { id: 'card2', component: (data) => <CardServerTimeIP data={data} />, gridSize: 6 },
    { id: 'card3', component: (data) => <CardPlayerStats data={data} />, gridSize: 6 },
  ];

  // Create a mapping from card IDs to card objects
  const cardsMap = initialCards.reduce((acc, card) => {
    acc[card.id] = card;
    return acc;
  }, {});

  // Load the card order from localStorage or use the initial order
  const [cardOrder, setCardOrder] = useState(() => {
    const savedOrder = localStorage.getItem('serverInfoCardOrder');
    return savedOrder ? JSON.parse(savedOrder) : initialCards.map((card) => card.id);
  });

  // Save the card order to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('serverInfoCardOrder', JSON.stringify(cardOrder));
  }, [cardOrder]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newOrder = Array.from(cardOrder);
    const [removed] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, removed);

    setCardOrder(newOrder);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="cardsDroppable">
        {(provided) => (
          <Grid
            container
            spacing={2}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {cardOrder.map((cardId, index) => {
              const card = cardsMap[cardId];
              return (
                <Draggable key={card.id} draggableId={card.id} index={index}>
                  {(provided) => (
                    <Grid
                      item
                      xs={12}
                      md={card.gridSize}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {card.component(data)}
                    </Grid>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default ServerInfo;
