// src/components/ServerSelector/ServerSelector.js
import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import config from '../../config/config';

function ServerSelector({ selectedServer, onServerChange }) {
  const [serverList, setServerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [polling, setPolling] = useState(false); // New state to manage polling for fresh data

  useEffect(() => {
    // Fetch server list when component mounts
    setLoading(true);
    axios
      .get(`${config.apiUrl}?path=servers`)
      .then((response) => {
        setServerList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching server list:', error);
        setLoading(false);
      });
  }, []);

  // Function to trigger GetData on the backend
  const triggerGetData = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}?action=triggerGetData`);
      console.log(response.data.status); // Logs 'GetData triggered successfully' if successful
      setPolling(true); // Start polling for new data
    } catch (error) {
      console.error('Error triggering GetData:', error);
    }
  };

  // Polling function to check if data is updated
  const pollForUpdatedData = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}`); // Fetch latest data
      const updatedData = response.data;

      // Check if the data is fresh and has updated info
      if (updatedData.serverInfo && updatedData.serverInfo.someFieldToCheckForUpdates) {
        setPolling(false); // Stop polling once fresh data is detected
        onServerChange(updatedData); // Update the component with the fresh data
      }
    } catch (error) {
      console.error('Error fetching updated data:', error);
    }
  };

  // Effect to manage polling intervals
  useEffect(() => {
    let intervalId;
    if (polling) {
      intervalId = setInterval(() => {
        pollForUpdatedData();
      }, 1000); // Poll every second
    }
    return () => clearInterval(intervalId); // Clear interval on component unmount or polling stop
  }, [polling]);

  const handleChange = (event) => {
    const selectedIpPort = event.target.value;
    onServerChange(selectedIpPort);

    // Trigger the backend function to update data
    triggerGetData();
  };

  return (
    <Box sx={{ marginBottom: 2 }}>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="server-select-label">Select Server</InputLabel>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Select
            labelId="server-select-label"
            id="server-select"
            value={selectedServer}
            onChange={handleChange}
            label="Select Server"
          >
            {serverList.map((server) => (
              <MenuItem key={server.ipPort} value={server.ipPort}>
                {server.displayName}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </Box>
  );
}

export default ServerSelector;
