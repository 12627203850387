// src/theme.js
import { createTheme } from '@mui/material/styles';

// Light theme
const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f5f5f5',
      paper: '#fff',
    },
    text: {
      primary: '#000000',
      secondary: '#444444',
    },
  },
});

// Dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212', // Darker background
      paper: '#1e1e1e', // Slightly lighter for cards or paper elements
    },
    text: {
      primary: '#ffffff', // White for better contrast
      secondary: '#b0b0b0', // Light grey for secondary text
    },
  },
});

export { lightTheme, darkTheme };
