// src/components/RevealCombo.js
import React, { useState } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

function RevealCombo({ code }) {
  const [revealed, setRevealed] = useState(false);

  const toggleReveal = () => setRevealed(!revealed);
  const digits = code ? code.toString().split('') : ['-', '-', '-', '-'];

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {revealed ? (
        digits.map((digit, index) => (
          <Typography key={index} variant="h6">
            {digit}
          </Typography>
        ))
      ) : (
        <Typography variant="h6">****</Typography>
      )}
      <IconButton onClick={toggleReveal}>
        {revealed ? <LockOpenIcon /> : <LockIcon />}
      </IconButton>
    </Box>
  );
}

export default RevealCombo;
