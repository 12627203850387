// src/components/TabsNavigator.js
import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

function TabsNavigator({ onTabChange }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
    >
      <Tab label="Server Info" />
      <Tab label="Locations/Combos" />
      <Tab label="SysInfo" /> {/* New SysInfo Tab */}
    </Tabs>
  );
}

export default TabsNavigator;
