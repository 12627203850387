// src/components/SysInfo.js
import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, CircularProgress, Box } from '@mui/material';
import axios from 'axios';

function SysInfo() {
  const [sysInfoData, setSysInfoData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the data from the provided API URL
    axios
      .get('https://script.google.com/macros/s/AKfycbzyPwTHR186SsPImo7iejpVey7Jy4Qt-hP-CV4pcqtBxWBc6EbfjcusV-CEMKCb7KEU/exec')
      .then((response) => {
        setSysInfoData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching SysInfo:', error);
        setLoading(false);
      });
  }, []);

  return (
    <Card sx={{ bgcolor: 'background.paper', color: 'text.primary', borderRadius: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
          SysInfo (API Data)
        </Typography>
        <Box sx={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <pre>{JSON.stringify(sysInfoData, null, 2)}</pre> // Pretty print the JSON data
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default SysInfo;
