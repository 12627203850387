// src/components/DigitalCombo.js
import React from 'react';
import { Box, Typography } from '@mui/material';

function DigitalCombo({ code }) {
  const digits = code ? code.toString().split('') : ['-', '-', '-', '-'];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#333',
        padding: '10px 20px',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      {digits.map((digit, index) => (
        <Typography
          key={index}
          variant="h5"
          sx={{
            color: '#0f0',
            fontFamily: 'monospace',
            fontWeight: 'bold',
            margin: '0 5px',
          }}
        >
          {digit}
        </Typography>
      ))}
    </Box>
  );
}

export default DigitalCombo;
