// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, CircularProgress } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import LightLogoSrc from '../assets/logo.png'; // Logo for light theme
import DarkLogoSrc from '../assets/logo-light.png'; // Logo for dark theme
import packageJson from '../../package.json'; // Import version from package.json

function Header({ toggleTheme, isDarkMode, loading, refreshData }) {
  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={refreshData}>
          <img
            src={isDarkMode ? DarkLogoSrc : LightLogoSrc} // Show dark logo in dark mode, light logo in light mode
            alt="LadZ Logo"
            style={{ height: 40, marginRight: 10 }}
          />
        </Box>

        {/* Display the spinner in the center of the header if loading */}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          {loading && <CircularProgress size={30} />}
        </Box>

        {/* Theme toggle button */}
        <IconButton onClick={toggleTheme} color="inherit">
          {isDarkMode ? <Brightness7 /> : <Brightness4 />}
        </IconButton>

        {/* Display version number from package.json, next to the theme toggle button */}
        <Typography variant="caption" sx={{ marginLeft: 2 }}>
          v{packageJson.version}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
