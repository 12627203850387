// src/components/ServerInfo/CardPhaseInformation.js
import React from 'react';
import { Typography, Card, CardContent, Divider } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import {
  WbSunny as WbSunnyIcon,
  NightsStay as NightsStayIcon,
  Brightness5 as Brightness5Icon,
  Brightness3 as Brightness3Icon,
  AccessTime as AccessTimeIcon,
} from '@mui/icons-material';

function getPhaseType(phaseName) {
  if (phaseName.startsWith('Day')) return 'Day';
  if (phaseName.startsWith('Night')) return 'Night';
  if (phaseName.startsWith('Sunrise')) return 'Sunrise';
  if (phaseName.startsWith('Sunset')) return 'Sunset';
  return '';
}

function CardPhaseInformation({ data }) {
  const phaseIcons = {
    Day: <WbSunnyIcon />,
    Night: <NightsStayIcon />,
    Sunrise: <Brightness5Icon />,
    Sunset: <Brightness3Icon />,
  };

  const phases = [
    { name: data.currentPhase, time: 'Now' },
    { name: data.nextPhase, time: '' },
    { name: data.followingPhase, time: '' },
    { name: data.thirdPhase, time: '' },
    { name: data.fourthPhase, time: '' },
  ];

  return (
    <Card
      sx={{
        bgcolor: 'background.paper',
        color: 'text.primary',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
          Upcoming Phases
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Timeline position="alternate">
          {phases.map((phase, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot color={index === 0 ? 'primary' : 'grey'}>
                  {phaseIcons[getPhaseType(phase.name)] || <AccessTimeIcon />}
                </TimelineDot>
                {index < phases.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  textAlign: index % 2 === 0 ? 'left' : 'right',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: index === 0 ? 'bold' : 'normal',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end',
                  }}
                >
                  {index % 2 === 0 ? (
                    <>
                      {phaseIcons[getPhaseType(phase.name)] || <AccessTimeIcon />}
                      <span style={{ marginLeft: 8 }}>
                        {phase.name.replace(/^(Day|Night|Sunrise|Sunset)/, '').trim()}
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ marginRight: 8 }}>
                        {phase.name.replace(/^(Day|Night|Sunrise|Sunset)/, '').trim()}
                      </span>
                      {phaseIcons[getPhaseType(phase.name)] || <AccessTimeIcon />}
                    </>
                  )}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}

export default CardPhaseInformation;
