// src/components/ServerInfo/CardServerTimeIP.js
import React from 'react';
import { Typography, Card, CardContent, Grid, Divider } from '@mui/material';
import { AccessTime as AccessTimeIcon, Public as PublicIcon } from '@mui/icons-material';

function CardServerTimeIP({ data }) {
  return (
    <Card
      sx={{
        bgcolor: 'background.paper',
        color: 'text.primary',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
          Server Info
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <AccessTimeIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              {data.mapName} Time: {data.serverTime}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              <PublicIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Server IP: {data.serverIP}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Last Update:{' '}
              {new Date(data.lastSuccessfulUpdate).toLocaleString()}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CardServerTimeIP;
