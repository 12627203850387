// src/components/KeypadCombo.js
import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

function KeypadCombo({ code }) {
  const digits = code ? code.toString().split('') : ['-', '-', '-', '-'];

  return (
    <Grid container spacing={1} sx={{ justifyContent: 'center' }}>
      {digits.map((digit, index) => (
        <Grid item key={index}>
          <Paper
            sx={{
              width: 50,
              height: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 1,
              backgroundColor: '#e0e0e0',
              boxShadow: 3,
            }}
          >
            <Typography variant="h5">{digit}</Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}

export default KeypadCombo;
