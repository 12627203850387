// src/components/LockCombo.js
import React from 'react';
import { Box, Paper } from '@mui/material';

function LockCombo({ code }) {
  const digits = code ? code.toString().split('') : ['-', '-', '-', '-'];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}>
      {digits.map((digit, index) => (
        <Paper
          key={index}
          sx={{
            width: 50,
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: '#3c3c3c', // Darker background for dials
            boxShadow: 3,
            color: '#ffffff', // White text for better contrast
            fontSize: 24,
            fontWeight: 'bold',
          }}
        >
          {digit}
        </Paper>
      ))}
    </Box>
  );
}

export default LockCombo;
