// src/App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ThemeProvider, CssBaseline, CircularProgress, Container, FormControl, Select, MenuItem, InputLabel, Box, Typography } from '@mui/material';
import Header from './components/Header';
import TabsNavigator from './components/TabsNavigator';
import ServerInfo from './components/ServerInfo/ServerInfo';
import SysInfo from './components/SysInfo';
import LockCombo from './components/LockCombo';
import KeypadCombo from './components/KeypadCombo';
import RevealCombo from './components/RevealCombo';
import DigitalCombo from './components/DigitalCombo';
import { lightTheme, darkTheme } from './theme';
import config from './config/config';
import ServerSelector from './components/ServerSelector/ServerSelector';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? JSON.parse(savedTheme) : true; // Default to dark theme
  });

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false); // Track loading state
  const [selectedTab, setSelectedTab] = useState(0);
  const [comboStyle, setComboStyle] = useState('Dial'); // State to manage combo style
  const [selectedServer, setSelectedServer] = useState('');

  // Fetch data function
  const fetchData = () => {
    setLoading(true); // Start loading
    axios
      .get(`${config.apiUrl}?action=getData`)
      .then((response) => {
        setData(response.data); // Update data once fetched
        setLoading(false); // End loading
      })
      .catch((error) => {
        console.error('Error fetching server info:', error);
        setLoading(false); // End loading even on error
      });
  };

  // Fetch the current selected server when the app loads
  useEffect(() => {
    axios
      .get(`${config.apiUrl}?action=getSelectedServer`)
      .then((response) => {
        if (response.data.selectedServer) {
          setSelectedServer(response.data.selectedServer);
          fetchData(); // Fetch data for the selected server
        }
      })
      .catch((error) => {
        console.error('Error fetching current selected server:', error);
      });
  }, []);

  // useEffect to handle data fetching and setting up the interval for auto-refresh
  useEffect(() => {
    if (selectedServer) {
      // Set up the interval to refresh data based on the config value
      const intervalId = setInterval(() => {
        fetchData();
      }, config.autoRefreshInterval);

      // Clean up the interval when the component unmounts or selectedServer changes
      return () => clearInterval(intervalId);
    }
  }, [selectedServer]);

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', JSON.stringify(newTheme)); // Save theme preference to localStorage
  };

  const handleComboStyleChange = (event) => {
    setComboStyle(event.target.value);
  };

  const handleServerChange = (serverIpPort) => {
    setSelectedServer(serverIpPort);
    // Send GET request to backend to update server selection
    axios
      .get(`${config.apiUrl}?action=updateServer&selectedServer=${encodeURIComponent(serverIpPort)}`)
      .then((response) => {
        if (response.data.success) {
          console.log('Server selection updated successfully.');
          fetchData(); // Fetch updated data after server selection
        } else {
          console.error('Error updating server selection:', response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error updating server selection:', error);
      });
  };

  const renderComboComponent = (code) => {
    switch (comboStyle) {
      case 'Dial':
        return <LockCombo code={code} />;
      case 'Keypad':
        return <KeypadCombo code={code} />;
      case 'Reveal':
        return <RevealCombo code={code} />;
      case 'Digital':
        return <DigitalCombo code={code} />;
      default:
        return <LockCombo code={code} />; // Default to Dial style
    }
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <Header toggleTheme={toggleTheme} isDarkMode={isDarkMode} loading={loading} refreshData={fetchData} />
      <TabsNavigator onTabChange={handleTabChange} />
      <Container style={{ marginTop: 20 }}>
        {/* Server Selector */}
        <ServerSelector selectedServer={selectedServer} onServerChange={handleServerChange} />

        {loading ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </div>
        ) : data ? (
          selectedTab === 0 ? (
            <ServerInfo data={data.serverInfo} />
          ) : selectedTab === 1 ? (
            <div>
              {/* Combo Style Selector */}
              <FormControl fullWidth sx={{ marginBottom: 4 }}>
                <InputLabel id="combo-style-selector-label">Select Combo Style</InputLabel>
                <Select
                  labelId="combo-style-selector-label"
                  id="combo-style-selector"
                  value={comboStyle}
                  label="Select Combo Style"
                  onChange={handleComboStyleChange}
                >
                  <MenuItem value="Dial">Dial Style (Wheel)</MenuItem>
                  <MenuItem value="Keypad">Keypad Style</MenuItem>
                  <MenuItem value="Reveal">Reveal Style</MenuItem>
                  <MenuItem value="Digital">Digital Style</MenuItem>
                </Select>
              </FormControl>

              {/* Display Locations and Combos with the selected lock combo style */}
              {data.locations.map((location, index) => (
                <Box key={index} sx={{ marginBottom: 2 }}>
                  <Typography variant="h6">
                    {location.location} - {location.buildingModel}
                  </Typography>
                  {renderComboComponent(location.code)}
                </Box>
              ))}
            </div>
          ) : (
            <SysInfo /> // Display SysInfo component when SysInfo tab is selected
          )
        ) : (
          <p>Error loading data.</p>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default App;
