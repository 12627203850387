// src/components/ServerInfo/CardPlayerStats.js
import React from 'react';
import { Typography, Card, CardContent, Grid, Divider } from '@mui/material';
import { Person as PersonIcon, Queue as QueueIcon } from '@mui/icons-material';

function CardPlayerStats({ data }) {
  return (
    <Card
      sx={{
        bgcolor: 'background.paper',
        color: 'text.primary',
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
          Player Stats
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1">
              <PersonIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Players: {data.playerCount} / {data.maxPlayers}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">
              <QueueIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              In Queue: {data.inQueue || '0'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CardPlayerStats;
